import(/* webpackMode: "eager", webpackExports: ["AppModalProvider"] */ "/learn-web/apps/learn/containers/AppModalProvider/AppModalProvider.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/ComponentsWeb/BreadCrumb/BreadCrumb.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/ComponentsWeb/Button/Button.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/ComponentsWeb/Chip/Chip.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/ComponentsWeb/Heading/Heading.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/ComponentsWeb/Icon/Icon.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/ComponentsWeb/Table/Table.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/ComponentsWeb/Tooltip/Tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/FeatureFlaggedSection/FeatureFlaggedSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FeedbackLink"] */ "/learn-web/features/common/components/FeedbackLink/FeedbackLink.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/Header/ScrollIndicator/ScrollIndicator.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/Link/Link.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/SdkSampleCard/SdkSampleCard.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/TextLink/TextLink.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/UsdcAccessForm/UsdcAccessForm.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/components/VideoContent/VideoContent.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/common/containers/FormProvider/FormProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageFeedback"] */ "/learn-web/features/documentation/components/PageFeedback/PageFeedback.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileBreadcrumb"] */ "/learn-web/features/navigation/components/Breadcrumb/MobileBreadcrumb/MobileBreadcrumb.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/navigation/components/Dropdown/Dropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DesktopNavigation"] */ "/learn-web/features/navigation/components/Navigation/desktop/DesktopNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavigation"] */ "/learn-web/features/navigation/components/Navigation/mobile/MobileNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tutorials"] */ "/learn-web/features/quickstart/components/Tutorials/Tutorials.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/features/quickstart/components/WebSDK/WebSDK.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/learn-web/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/learn-web/node_modules/next-translate/lib/esm/AppDirI18nProvider.js");
;
import(/* webpackMode: "eager" */ "/learn-web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/learn-web/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/Banner/Banner.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/Carousel/Carousel.Item.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/Carousel/Carousel.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/Code/CodeConfigProvider.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/Code/CodeGroup/CodeGroup.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/Code/CodePanel/CodePanel.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/OSSwitch/OSSwitch.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/Playground/Playground.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CustomCodeSnippet"] */ "/learn-web/packages/ui/lib/components/Playgroundv2/CustomCodeSnippet/CustomCodeSnippet.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/Playgroundv2/Playground.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RequestTemplate"] */ "/learn-web/packages/ui/lib/components/Playgroundv2/RequestTemplate/RequestTemplate.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SetInitiator"] */ "/learn-web/packages/ui/lib/components/Playgroundv2/SetInitiator.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/RequestTemplate/RequestTemplate.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/SDKPlayground/SDKPlayground.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Content"] */ "/learn-web/packages/ui/lib/components/Tabbed/Content.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/components/Tabbed/Tabbed.tsx");
;
import(/* webpackMode: "eager" */ "/learn-web/packages/ui/lib/hooks/usePreventLayoutShift.ts");
